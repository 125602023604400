import React, { useEffect } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const Collage = ({ data }) => {
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#collage`,
          markers: false,
          start: "top 70%",
          toggleActions: "play none none none",
        },
      })
      .add("start")
      .fromTo(
        `#collage .image-one`,
        {
          autoAlpha: 0,
          x: -100,
        },
        {
          autoAlpha: 1,
          ease: "power2.out",
          x: 0,
          duration: 2,
          stagger: {
            each: 0.4,
          },
        },
        "start"
      )
      .fromTo(
        `#collage .image-two`,
        {
          autoAlpha: 0,
          y: 100,
        },
        {
          autoAlpha: 1,
          ease: "power2.out",
          y: 0,
          duration: 2,
          stagger: {
            each: 0.4,
          },
        },
        "start"
      )
      .fromTo(
        `#collage .image-three`,
        {
          autoAlpha: 0,
          x: 100,
        },
        {
          autoAlpha: 1,
          ease: "power2.out",
          x: 0,
          duration: 2,
          stagger: {
            each: 0.4,
          },
        },
        "start"
      )
  }, [])

  const imgOneDisplay = getImage(
    data.collageTopLeft.localFile.childImageSharp.gatsbyImageData
  )
  const imgOneAlt = data.collageTopLeft.altText
  const imgTwoDisplay = getImage(
    data.collageTopMiddle.localFile.childImageSharp.gatsbyImageData
  )
  const imgTwoAlt = data.collageTopMiddle.altText
  const imgThreeDisplay = getImage(
    data.collageTopRight.localFile.childImageSharp.gatsbyImageData
  )
  const imgThreeAlt = data.collageTopRight.altText
  const imgFourDisplay = getImage(
    data.collageBigImage.localFile.childImageSharp.gatsbyImageData
  )
  const imgFourAlt = data.collageBigImage.altText
  return (
    <StyledDiv id="collage">
      <div className="top-row">
        <div className="image-one top-row__image">
          <div className="top-row__image--container">
            <GatsbyImage
              image={imgOneDisplay}
              alt={imgOneAlt}
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </div>
        </div>
        <div className="image-two top-row__image">
          <div className="top-row__image--container">
            <GatsbyImage
              image={imgTwoDisplay}
              alt={imgTwoAlt}
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </div>
        </div>
        <div className="image-three top-row__image">
          <div className="top-row__image--container">
            <GatsbyImage
              image={imgThreeDisplay}
              alt={imgThreeAlt}
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </div>
        </div>
      </div>
      <div className="bottom-row">
        <GatsbyImage
          image={imgFourDisplay}
          alt={imgFourAlt}
          layout="fullWidth"
          formats={["auto", "webp", "avif"]}
        />
      </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  .top-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 1.5rem;

    &__image {
      position: relative;
      width: calc(100%);
      height: 40rem;
      margin-bottom: 1.5rem;

      @media (min-width: 768px) {
        width: calc(33.333333% - 1rem);
        height: 25vw;
        margin-bottom: auto;

        &:nth-of-type(2) {
          margin-left: 1.5rem;
        }

        &:nth-of-type(3) {
          margin-left: 1.5rem;
        }
      }

      &--container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .gatsby-image-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: calc(100%);
          height: 100%;

          img {
            width: 100% !important;
          }
        }
      }
    }
  }
`

export default Collage
