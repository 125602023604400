import React, { useEffect } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  B1DarkGrey,
  Btn1Three,
  colors,
  H2MidBlue,
  standardWrapper,
} from "../../styles/helpers"

import bannerImage from "../../images/news-updates.png"
import greyStripe from "../../images/grey-stripe.png"
import greyStripeAlt from "../../images/grey-stripe-alt.png"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const getData = graphql`
  {
    posts: allWpPost(sort: { order: DESC, fields: date }, limit: 1) {
      edges {
        node {
          id
          title
          slug
          post {
            postExcerpt
            featuredImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }
  }
`

const LatestPost = ({ data }) => {
  const postData = useStaticQuery(getData)
  const posts = postData?.posts?.edges ? postData.posts.edges : []

  useEffect(() => {
    gsap
      .timeline({
        repeat: -1,
        yoyo: true,
      })
      .fromTo(
        `.banner`,
        {
          x: 4,
          rotate: "3deg",
        },
        {
          ease: "none",
          x: -4,
          rotate: "-3deg",
          duration: 1,
        }
      )

    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#latest-post`,
          markers: false,
          start: "top 60%",
          toggleActions: "play none none none",
        },
      })
      .add("start")
      .fromTo(
        `#latest-post .banner`,
        {
          autoAlpha: 0,
          x: 100,
        },
        {
          autoAlpha: 1,
          ease: "power2.out",
          x: 0,
          duration: 2,
        },
        "start"
      )
      .fromTo(
        `#latest-post .image`,
        {
          autoAlpha: 0,
          x: -100,
        },
        {
          autoAlpha: 1,
          ease: "power2.out",
          x: 0,
          duration: 2,
        },
        "start"
      )
      .fromTo(
        `#latest-post .content__wrapper`,
        {
          autoAlpha: 0,
          y: 100,
        },
        {
          autoAlpha: 1,
          ease: "power2.out",
          y: 0,
          duration: 2,
        },
        "start"
      )
      .fromTo(
        `#latest-post .main-link`,
        {
          autoAlpha: 0,
          x: 100,
        },
        {
          autoAlpha: 1,
          ease: "power2.out",
          x: 0,
          duration: 2,
        },
        "start"
      )
  }, [])

  if (posts.length === 0) {
    return (
      <div>
        <p>Sorry no posts were found!</p>
      </div>
    )
  }

  const imgDisplay = getImage(
    posts[0].node.post.featuredImage.localFile.childImageSharp.gatsbyImageData
  )
  const imgAlt = posts[0].node.post.featuredImage.altText

  return (
    <StyledSection id="latest-post">
      <div className="wrapper">
        <Card to={`/news/${posts[0].node.slug}`}>
          <div className="image">
            <div className="image__container">
              <GatsbyImage
                image={imgDisplay}
                alt={imgAlt}
                layout="fullWidth"
                formats={["auto", "webp", "avif"]}
              />
            </div>
          </div>
          <div className="content">
            <div className="content__wrapper">
              <div className="content__title">
                <h3>{posts[0].node.title}</h3>
              </div>
              <div className="content__excerpt">
                <p>{posts[0].node.post.postExcerpt}</p>
              </div>
              <div className="content__link">
                <Link
                  className="content__link--button"
                  to={`/news-updates/${posts[0].node.slug}`}
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="main-link">
              <Link to={`/news-updates`}>MORE NEWS + UPDATES</Link>
            </div>
          </div>
          <div className="banner">
            <img src={bannerImage} alt={``} />
          </div>
        </Card>
      </div>
      <div className="bg-color__graphic" />
      <div className="bg-color__graphic-alt" />
    </StyledSection>
  )
}

const StyledSection = styled.section`
  position: relative;
  margin-top: 7.4rem;
  padding: 10rem 0 0;
  background-color: ${colors.colorShad};

  .bg-color__graphic,
  .bg-color__graphic-alt {
    position: absolute;
    left: -5%;
    padding-bottom: 15rem;
    width: 110%;
    background-size: 100% 100%;
    background-repeat: repeat-x;
    background-position: bottom center;
  }

  .bg-color__graphic {
    top: -5rem;
    background-image: url(${greyStripe});
  }

  .bg-color__graphic-alt {
    bottom: -5rem;
    background-image: url(${greyStripeAlt});
    z-index: 1;
  }

  .wrapper {
    ${standardWrapper};
    position: relative;
    z-index: 100;

    @media (min-width: 1025px) {
      max-width: 90rem !important;
    }
  }
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 3.5rem;

  @media (min-width: 768px) {
    height: 65rem;
  }

  @media (min-width: 1025px) {
    height: 75rem;
  }

  .banner {
    position: absolute;
    top: -15rem;
    right: 0;
    width: 17.5rem;

    @media (min-width: 768px) {
      top: -5rem;
      width: 28.25rem;
    }

    img {
      width: 100%;
    }
  }

  .image {
    position: relative;
    width: 100%;
    height: 30rem;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);

    @media (min-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
      width: 60%;
      height: 38.5rem;
    }

    &__container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .gatsby-image-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100%);
        height: 100%;

        img {
          width: 100% !important;
        }
      }
    }
  }

  .content {
    width: 100%;
    padding: 1rem 0;

    @media (min-width: 768px) {
      position: absolute;
      top: 25%;
      right: 0;
      width: 60%;
      padding: 3.1rem;
    }

    .main-link {
      width: 100%;
      margin-top: 3rem;
      text-align: right;

      @media (min-width: 768px) {
        margin-top: 1rem;
      }

      a {
        ${Btn1Three};
      }
    }

    &__wrapper {
      background-color: ${colors.white};
      padding: 2.5rem;

      @media (min-width: 768px) {
        padding: 6.5rem;
      }
    }

    &__title {
      width: 100%;

      h3 {
        ${H2MidBlue};
        margin: 0;
        margin-bottom: 1.5rem;
        font-weight: 600;
      }
    }

    .content__excerpt {
      width: 100%;

      p {
        ${B1DarkGrey};
      }
    }

    .content__link {
      width: 100%;

      &--button {
        ${B1DarkGrey};
        font-weight: 600;
      }
    }
  }
`

export default LatestPost
