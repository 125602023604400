import React, { useEffect } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  B1White,
  colors,
  H2White,
  H3White,
  medWrapper,
} from "../../styles/helpers"

import dotGraphic from "../../images/dot-graphic.png"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const ColouredSection = ({ data }) => {
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#coloured-section`,
          markers: false,
          start: "top 70%",
          end: "bottom 0%",
          scrub: true,
          invalidateOnRefresh: true, // to make it responsive
        },
      })
      .fromTo(
        `#coloured-section .dot-graphic`,
        {
          y: 100,
          ease: "none",
        },
        { y: -75, ease: "none" }
      )
  }, [])

  const imgBotDisplay = getImage(
    data.colouredSectionBottomImage.localFile.childImageSharp.gatsbyImageData
  )
  const imgBotAlt = data.colouredSectionBottomImage.altText
  return (
    <StyledSection id="coloured-section">
      <div className="wrapper">
        <div className="box-content">
          <div className="box-content__title">
            <h2>{data.colouredSectionTopBoxTitle}</h2>
          </div>
          <div
            className="box-content__content"
            dangerouslySetInnerHTML={{ __html: data.colouredSectionContent }}
          />
        </div>

        <div className="logos">
          <div className="logos__title">
            <h3>{data.colouredSectionLogosTitle}</h3>
          </div>
          <div className="logos__container">
            {data.colouredSectionLogos.map((logo, index) => {
              const imgDisplay = getImage(
                logo.logo.localFile.childImageSharp.gatsbyImageData
              )
              const imgAlt = logo.logo.altText

              if (logo.url) {
                return (
                  <Logo key={index}>
                    <a href={logo.url} rel="noreferrer" target="_blank">
                      <GatsbyImage
                        image={imgDisplay}
                        alt={imgAlt}
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                    </a>
                  </Logo>
                )
              }

              return (
                <Logo key={index}>
                  <GatsbyImage
                    image={imgDisplay}
                    alt={imgAlt}
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                </Logo>
              )
            })}
          </div>
        </div>

        <div className="bot-content">
          <div
            className="bot-content__content"
            dangerouslySetInnerHTML={{
              __html: data.colouredSectionContentBottom,
            }}
          />
          <div className="bot-content__image">
            <GatsbyImage
              image={imgBotDisplay}
              alt={imgBotAlt}
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </div>
        </div>
      </div>
      <div className="dot-graphic">
        <img src={dotGraphic} alt="" />
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  position: relative;
  padding-top: 7rem;
  padding-bottom: 5rem;
  background-color: ${colors.colorSecondary};

  .dot-graphic {
    position: absolute;
    top: 25%;
    right: 0;
    width: 21.3rem;
    z-index: 1;
  }

  .wrapper {
    ${medWrapper};
    position: relative;
    z-index: 10;
  }

  .box-content {
    width: 100%;
    padding: 5rem 2rem;
    background-color: rgba(33, 35, 38, 0.25);

    @media (min-width: 768px) {
      padding: 5rem 6rem;
    }

    &__title {
      width: 100%;
      text-align: center;

      h2 {
        ${H2White};
        margin-top: 0;
      }
    }

    &__content {
      width: 100%;
      text-align: center;

      p {
        ${B1White};

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .logos {
    width: 100%;
    margin-top: 6.5rem;

    &__title {
      width: 100%;
      text-align: center;

      h3 {
        ${H2White};
      }
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .bot-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 5rem;

    &__content {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(75% - 4rem);
        margin-right: 4rem;
        text-align: right;
      }

      p {
        ${H3White};
        font-weight: 300;

        &:last-of-type {
          margin: 0;
        }
      }
    }

    &__image {
      width: calc(100%);
      margin: 5rem auto;
      max-width: 30rem;

      @media (min-width: 768px) {
        width: calc(25%);
        max-width: 100%;
        margin: auto;
      }
    }
  }
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  width: calc(50%);
  padding: 2rem 3rem;
  background-color: ${colors.white};

  @media (min-width: 768px) {
    width: 20rem;
    margin-left: 1rem;
  }
`

export default ColouredSection
