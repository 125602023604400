import React, { useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  B1DarkGrey,
  Btn1One,
  H3DarkGrey,
  medWrapper,
} from "../../styles/helpers"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const Intro = ({ data }) => {
  useEffect(() => {
    gsap
      .timeline({
        repeat: -1,
        yoyo: true,
      })
      .fromTo(
        `.intro-top__icon`,
        {
          x: 4,
          rotate: "3deg",
        },
        {
          ease: "none",
          x: -4,
          rotate: "-3deg",
          duration: 1,
        }
      )
  }, [])

  const iconDisplay = getImage(
    data.introIcon.localFile.childImageSharp.gatsbyImageData
  )
  const iconAlt = data.introIcon.altText

  return (
    <StyledSection>
      <div className="wrapper">
        <div className="intro-top">
          <div className="intro-top__icon">
            <GatsbyImage
              image={iconDisplay}
              alt={iconAlt}
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <div
            className="intro-top__content"
            dangerouslySetInnerHTML={{ __html: data.introTopContent }}
          />
        </div>
        <div className="intro-bot">
          <div className="intro-bot__title">
            <h2>{data.introBottomTitle}</h2>
          </div>
          <div
            className="intro-bot__bot-content"
            dangerouslySetInnerHTML={{ __html: data.introBottomContent }}
          />
        </div>

        <div className="intro-button">
          <Link to={`/${data.introButtonSlug}`}>{data.introButtonText}</Link>
        </div>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  padding: 10rem 0;

  .wrapper {
    ${medWrapper};

    @media (min-width: 1200px) {
      max-width: 115rem !important;
    }
  }

  .intro-top {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    &__icon {
      width: 100%;
      max-width: 19rem;
      margin: 0 auto 5rem;

      @media (min-width: 768px) {
        width: calc(20%);
        margin: 0 auto 5rem;
      }

      @media (min-width: 1025px) {
        width: calc(20% - 2rem);
        margin-right: 2rem;
        max-width: 19rem;
      }
    }

    &__content {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(80%);
      }

      @media (min-width: 1025px) {
        width: calc(80% - 2rem);
        margin-left: 2rem;
      }

      p {
        ${H3DarkGrey};
        font-weight: 300;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .intro-bot {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top: 6rem;

    &__title {
      width: 100%;

      h2 {
        ${B1DarkGrey};
        font-weight: bold;
      }
    }

    &__bot-content {
      p {
        ${B1DarkGrey};

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .intro-button {
    width: 100%;
    margin-top: 8rem;

    a {
      ${Btn1One};
    }
  }
`

export default Intro
