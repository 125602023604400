import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Hero from "../components/home/Hero"
import Intro from "../components/home/Intro"
import Collage from "../components/home/Collage"
import ImageUpdate from "../components/home/ImageUpdate"
import LatestPost from "../components/home/LatestPost"
import ColouredSection from "../components/home/ColouredSection"

const IndexPage = props => {
  const { seoInfo } = props.data
  const hero = props?.data?.hero?.template?.homeTemplate
  const intro = props?.data?.intro?.template?.homeTemplate
  const collage = props?.data?.collage?.template?.homeTemplate
  const imageUpdate = props?.data?.imageUpdate?.template?.homeTemplate
  const latestPost = props?.data?.latestPost?.template?.homeTemplate
  const colouredSection = props?.data?.colouredSection?.template?.homeTemplate
  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <Hero data={hero} />
      <Intro data={intro} />
      <Collage data={collage} />
      <ImageUpdate data={imageUpdate} />
      <LatestPost data={latestPost} />
      <ColouredSection data={colouredSection} />
    </Layout>
  )
}

export const homeQuery = graphql`
  {
    seoInfo: wpPage(slug: { eq: "home" }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    hero: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          homeTemplate {
            heroTitle
            heroImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }

    intro: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          homeTemplate {
            introTopContent
            introBottomTitle
            introBottomContent
            introButtonText
            introButtonSlug
            introIcon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }

    collage: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          homeTemplate {
            collageTopLeft {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
            collageTopMiddle {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
            collageTopRight {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
            collageBigImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }

    imageUpdate: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          homeTemplate {
            imageUpdateTitle
            imageUpdateContent
            imageUpdateText
            imageUpdateSlug
            imageUpdateImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }

    latestPost: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          homeTemplate {
            latestPostDisplay
          }
        }
      }
    }

    colouredSection: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          homeTemplate {
            colouredSectionTopBoxTitle
            colouredSectionContent
            colouredSectionLogosTitle
            colouredSectionLogos {
              logo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000)
                  }
                }
              }
              url
            }
            colouredSectionContentBottom
            colouredSectionBottomImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
