import React, { useEffect } from "react"
import styled from "styled-components"
import { H1White, standardWrapper } from "../../styles/helpers"
import HeroImage from "./HeroImage"
import { gsap } from "gsap"

import topBurst from "../../images/top-burst.png"
import botBurst from "../../images/bottom-burst.png"

const Hero = ({ data }) => {
  const bgImg = data?.heroImage

  useEffect(() => {
    gsap
      .timeline({
        repeat: 4,
        yoyo: true,
      })
      .add("start")
      .fromTo(
        `#home-hero .home-hero-title span`,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          ease: "power2.out",
          stagger: {
            each: 0.25,
          },
        }
      )
  }, [])

  return (
    <StyledSection id="home-hero">
      {bgImg && <HeroImage bgImg={bgImg} />}
      <div className="home-hero-title">
        <h1>
          <span>
            <img src={topBurst} alt="" />
          </span>
          {data?.heroTitle}
          <span>
            <img src={botBurst} alt="" />
          </span>
        </h1>
      </div>
      <div className="hero-overlay" />
    </StyledSection>
  )
}

const StyledSection = styled.div`
  position: relative;
  width: 100%;
  height: 50rem;
  margin-top: -5rem;
  z-index: 10;

  @media (min-width: 768px) {
    height: 60rem;
    margin-top: -7.5rem;
  }

  @media (min-width: 1025px) {
    height: 86.8rem;
    margin-top: -10rem;
  }

  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    z-index: 1;
  }

  .home-hero-title {
    ${standardWrapper};
    position: absolute;
    right: 0;
    bottom: 5rem;
    left: 0;
    text-align: center;
    z-index: 10;

    @media (min-width: 768px) {
      bottom: 7.5rem;
      margin: auto;
    }

    @media (min-width: 1025px) {
      bottom: 7rem;
      max-width: 83.8rem !important;
    }

    h1 {
      ${H1White};
      margin: 0;
      padding: 0;
      width: 100%;
      text-align: center;

      span {
        display: block;
        max-width: 26.6rem;
        margin: auto;
      }
    }
  }
`

export default Hero
