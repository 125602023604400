import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  B1White,
  Btn1Two,
  colors,
  H2White,
  medWrapper,
} from "../../styles/helpers"

import orangeStripe from "../../images/orange-stripe.png"
import orangeStripeAlt from "../../images/orange-stripe-alt.png"
import speechBubble from "../../images/speech-bubble.png"

const ImageUpdate = ({ data }) => {
  const imgDisplay = getImage(
    data.imageUpdateImage.localFile.childImageSharp.gatsbyImageData
  )
  const imgAlt = data.imageUpdateImage.altText
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="title">
          <h2>
            <span className="title__icon">
              <img src={speechBubble} alt={``} />
            </span>
            <span className="title__text">We are Here…</span>
          </h2>
        </div>
        <PostCard>
          <div className="image">
            <GatsbyImage
              image={imgDisplay}
              alt={imgAlt}
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </div>
          {data.imageUpdateTitle || data.imageUpdateContent ? (
            <div className="content">
              {data.imageUpdateTitle ? (
                <div className="content__title">
                  <h2>{data.imageUpdateTitle}</h2>
                </div>
              ) : null}
              {data.imageUpdateContent ? (
                <div
                  className="content__paragraphs"
                  dangerouslySetInnerHTML={{
                    __html: data.imageUpdateContent,
                  }}
                />
              ) : null}
            </div>
          ) : null}
        </PostCard>
        <div className="link">
          <Link to={`/${data.imageUpdateSlug}`}>{data.imageUpdateText}</Link>
        </div>
      </div>
      <div className="bg-color">
        <div className="bg-color__graphic" />
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  position: relative;
  padding-top: 10rem;

  .title {
    position: absolute;
    top: -7rem;
    margin: auto;
    text-align: center;

    h2 {
      ${H2White};
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 3rem 5rem 2rem;
      background-image: url(${orangeStripeAlt});
      background-size: 100% 100%;
      background-repeat: repeat-x;
      background-position: bottom center;

      .title__icon {
        width: 7rem;
      }

      .title__text {
        padding-left: 2rem;
      }
    }
  }

  .bg-color {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: ${colors.colorTertiary};
    z-index: -1;

    &__graphic {
      position: absolute;
      bottom: -5rem;
      left: -10%;
      padding-bottom: 15rem;
      width: 120%;
      background-image: url(${orangeStripe});
      background-size: 100% 100%;
      background-repeat: repeat-x;
      background-position: bottom center;
    }
  }

  .wrapper {
    ${medWrapper};

    @media (min-width: 1200px) {
      max-width: 115rem !important;
    }
  }

  .link {
    width: 100%;
    margin-top: 4.5rem;
    text-align: center;

    a {
      ${Btn1Two};
    }
  }
`

const PostCard = styled.div`
  position: relative;
  width: 100%;
  height: 40rem;

  @media (min-width: 768px) {
    height: 65rem;
  }

  @media (min-width: 1025px) {
    height: 75rem;
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;

    .gatsby-image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100%);
      height: 100%;

      img {
        width: 100% !important;
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;

    @media (min-width: 768px) {
      display: block;
      top: auto;
      padding: 2rem 8rem;
    }

    .content__title,
    .content__paragraphs {
      width: 100%;
    }

    h2,
    p {
      ${B1White};
      width: 100%;
    }
  }
`

export default ImageUpdate
